import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from './services/state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  title = 'psa-utility';
 
  constructor(
    private router: Router,
    private stateService: StateService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params: any) => {
      if (params.access_token && params.refresh_token) {
        window.localStorage['c'] = params.access_token;
        window.localStorage['d'] = params.refresh_token;
        window.localStorage['i']= params.id;
        // This is the first time here we set the tokenExpiryDate
        this.stateService.setTokenExpiryDate();
        let redirectURL =
          sessionStorage.getItem("callbackURL") || this.router.url;

        var currentUrl =
          this.stateService.removeQueryParamsFromUrl(redirectURL);
        this.router.navigate([currentUrl], {
          replaceUrl: true,
        });
      }
    });
  }
  ngOnInit(): void {
  }
}
