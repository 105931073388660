<div class="login login-container">
  <div class="bg">
    <div class="login-bg-backdrop">
      <img
        src="/assets/images/catman-logo-white-black.png"
        class="login-banner-logo"
      />
    </div>
  </div>
  <div class="login-section">
    <form>
      <div>
        <img
          src="/assets/images/login-logo.png"
          class="login-logo"
        />
      </div>
      <ng-container>
        <div>
          <div class="btn btn-primary login-btn hover:cursor-pointer" (click)="login()">
            <span class="login-btn-text">Login with ABInBev</span>
            <img
              class="collection-forward"
              src="/assets/images/white-arrow.svg"
            />
          </div>
        </div>
        <div>
          <label class="form-check-label my-4"
            >Don’t have an account?
            <a href="/home#onboarding" class="login-sign-up"
              >Raise onboarding request
            </a></label
          >
        </div>
      </ng-container>
    </form>
  </div>
</div>
