import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    if (
      !window.localStorage['c']||
      !window.localStorage['i'] ||
      !window.localStorage['d']
    ) {
      return true;
    }

    this.router.navigateByUrl("/studio");
    return false;
  }
}

@Injectable({
  providedIn: "root",
})
export class VerifyAuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    if (
      window.localStorage['c'] ||
      window.localStorage['i'] ||
      window.localStorage['d']
    ) {
      return true;
    }

    this.router.navigateByUrl("/login");
    return false;
  }
}
