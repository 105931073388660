import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth.service';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { 
    path: "login", 
    canActivate: [AuthGuard],
    component: LoginComponent 
  },
  {
    path: "studio",
    loadChildren: () => import('./main-container/main-container.module')
    .then(m => m.MainContainerModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
