import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { StateService } from "./state.service";
import { catchError, switchMap } from "rxjs/operators";

import { NavigationStart, Router } from "@angular/router";
import { HttpService } from "./http.service";
import { environment } from "../../environments/environment";

const API_URL = environment.apiUrl;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refererUrl: string = "";

  constructor(
    private state: StateService,
    private router: Router,
    private http: HttpService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // saving referer url for redirection
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.refererUrl = event.url;
      }
    });

    let token = this.state.getToken();
    if (token && request.url.includes(`${API_URL}`)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes("login") &&
          error.status === 401
        ) {
          return this.handle401Error(request, next);
        }

        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes("login") &&
          error.status === 403
        ) {
          return this.handle403Error(request, next);
        }
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes("login") &&
          error.status === 409
        ) {
          this.state.popOutCalledApi();
          // this.state.openSoftWarningToastService(
          //   error.error.message
          //     ? error.error.message
          //     : "Please make sure all the required fields are entered correctly"
          // );
        }
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes("login") &&
          error.status === 422 &&
          !request.url.includes("products/upload")
        ) {
          this.state.popOutCalledApi();
          // this.state.openSoftWarningToastService(
          //   error.error.message
          //     ? error.error.message
          //     : "Please make sure all the required fields are entered correctly"
          // );
        }
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes("login") &&
          error.status === 500
        ) {
          this.state.popOutCalledApi();
          // this.state.openSoftWarningToastService(
          //   error.error.message
          //     ? error.error.message
          //     : "Something went wrong, Please try again"
          // );
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !window.localStorage['i'] &&
      !this.router.url.includes("home") &&
      !this.router.url.includes("login")
    ) {
      sessionStorage.setItem("callbackURL", this.refererUrl);
      window.location.replace(this.state.apiList.user.login);
      return throwError("");
    }

    const refreshUrl = `${this.state.apiList.user.refreshToken}`;
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.state.addCalledApi(refreshUrl);
      // Arrive at params for token refreshing
      window.localStorage['c'] = window.localStorage['d'];

      return this.http.postMethod(refreshUrl).pipe(
        switchMap((data: any) => {
          if (data.access_token) {
            this.state.removeCalledApi(refreshUrl);
            //Update tokens
            window.localStorage['c'] = data.access_token;
            window.localStorage['d'] = data.refresh_token;

            // Arrive at new tokenExpiryDate
            this.state.setTokenExpiryDate();
            this.isRefreshing = false;
          }
          let token = this.state.getToken();
          if (token && request.url.includes(`${API_URL}`)) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              },
            });
          }
          return next.handle(request);
        }),
        catchError((error) => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          this.isRefreshing = false;
          this.state.removeCalledApi(refreshUrl);
          window.localStorage.clear();
          window.sessionStorage.clear();
          if (
            !this.router.url.includes("home") &&
            !this.router.url.includes("login")
          ) {
            sessionStorage.setItem("callbackURL", this.refererUrl);
            window.location.replace(this.state.apiList.user.login);
          }
          return throwError("");
        })
      );
    } else if (!request.url.includes(refreshUrl)) {
      let token = this.state.getToken();
      if (token && request.url.includes(`${API_URL}`)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      return next.handle(request).pipe(
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            !request.url.includes("login") &&
            error.status === 401
          ) {
            return this.handle401Error(request, next);
          }

          if (
            error instanceof HttpErrorResponse &&
            !request.url.includes("login") &&
            error.status === 403
          ) {
            return this.handle403Error(request, next);
          }

          return throwError(error);
        })
      );
    } else {
      let token = this.state.getToken();
      if (token && request.url.includes(`${API_URL}`)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      return next.handle(request);
    }
  }

  private handle403Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !window.localStorage['i'] &&
      !this.router.url.includes("home") &&
      !this.router.url.includes("login")
    ) {
      sessionStorage.setItem("callbackURL", this.refererUrl);
      window.location.replace(this.state.apiList.user.login);
      return throwError("");
    }
    return throwError("");
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    throw response;
  }
}
